import React, { useEffect, useState } from 'react'
import { MainMenus } from '@/components/layout/includes/menu.constant'

import { useAnimate, stagger } from 'framer-motion'

import { MenuToggle } from '@/components/layout/includes/mobile-menu/MenuToggle'
import ParentMenu from '@/components/layout/includes/mobile-menu/ParentMenu'
import Link from 'next/link'
import { cn } from '@/utils/tsx-helpers'
import CONFIGS from '@/configs'

const staggerMenuItems = stagger(0.1, { startDelay: 0.15 })

function useMenuAnimation(isOpen) {
  const [scope, animate] = useAnimate()

  useEffect(() => {
    animate([
      ['path.top', { d: isOpen ? 'M 3 16.5 L 17 2.5' : 'M 2 2.5 L 20 2.5' }, { at: '<' }],
      ['path.middle', { opacity: isOpen ? 0 : 1 }, { at: '<' }],
      ['path.bottom', { d: isOpen ? 'M 3 2.5 L 17 16.346' : 'M 2 16.346 L 20 16.346' }, { at: '<' }]
    ])
    animate(
      '#menu-wrapper',
      {
        clipPath: isOpen ? 'inset(0% 0% 0% 0% round 10px)' : 'inset(10% 50% 90% 50% round 10px)'
      },
      {
        type: 'spring',
        bounce: 0,
        duration: 0.3
      }
    )

    animate(
      'li.parent-li',
      isOpen ? { opacity: 1, scale: 1, filter: 'blur(0px)' } : { opacity: 0, scale: 0.3, filter: 'blur(20px)' },
      {
        duration: 0.2,
        delay: isOpen ? staggerMenuItems : 0
      }
    )
  }, [isOpen])

  return scope
}

export default function MobileMenus({ dashboardRedirect, authenticated, processSignOut }) {
  const [activeTab, setActiveTab] = useState(null)
  const [isOpen, setIsOpen] = useState(false)
  const scope = useMenuAnimation(isOpen)

  const handleToggle = () => {
    setIsOpen(!isOpen)
    setActiveTab(isOpen ? null : activeTab)
  }

  return (
    <>
      <nav className={'relative flex xl:hidden'} ref={scope}>
        <div
          className={'fixed bottom-0 left-0 right-0 top-[68px] z-[99] bg-transparent backdrop-blur-[2px]'}
          onClick={handleToggle}
          style={{
            display: isOpen ? 'block' : 'none'
          }}
        />
        <div>
          <MenuToggle toggle={handleToggle} />

          <div
            id={'menu-wrapper'}
            className={'fixed bottom-auto left-auto right-0 top-[66px] z-[100] w-[360px] lg:top-[88px]'}
            style={{
              pointerEvents: isOpen ? 'auto' : 'none',
              clipPath: 'inset(10% 50% 90% 50% round 10px)'
            }}
          >
            <ul
              className={
                'scrollbar-hidden mb-[50px] ml-auto mr-4 mt-0 max-h-[calc(100vh-68px)] min-h-[250px] w-[310px] overflow-y-auto rounded bg-white px-5 pt-4 shadow-2xl sm:mr-6 lg:max-h-[calc(100vh-88px)]'
              }
            >
              {MainMenus.map((page, index) => (
                <ParentMenu
                  key={index}
                  item={page}
                  setIsOpen={setIsOpen}
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                />
              ))}

              <li className={'parent-li mb-[40px] mt-[54px] flex flex-col items-center justify-center gap-[28px]'}>
                {authenticated ? (
                  <>
                    <a href={`/logout`} onClick={e => processSignOut(e)} className={'w-full'}>
                      <ActionButton>Sign Out</ActionButton>
                    </a>
                    <Link href={dashboardRedirect} className={`w-full`}>
                      <ActionButton
                        className={
                          'focus::border-[#0600F9] focus-visible::border-[#0600F9] bg-[#0600F9] font-semibold text-white hover:border-[#0600F9] hover:bg-[#0600F9] focus:bg-[#0600F9] focus-visible:bg-[#0600F9]'
                        }
                      >
                        Dashboard
                      </ActionButton>
                    </Link>
                  </>
                ) : (
                  <>
                    <Link href={`${CONFIGS.authUrl}/login`} className='w-full'>
                      <ActionButton>Sign In</ActionButton>
                    </Link>
                    <Link href={'/pricing'} className='w-full'>
                      <ActionButton
                        className={
                          'focus::border-[#0600F9] focus-visible::border-[#0600F9] bg-[#0600F9] font-semibold text-white hover:border-[#0600F9] hover:bg-[#0600F9] focus:bg-[#0600F9] focus-visible:bg-[#0600F9]'
                        }
                      >
                        Get Started
                      </ActionButton>
                    </Link>
                  </>
                )}
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  )
}

const ActionButton = ({ className, children }) => {
  return (
    <button
      className={cn(
        `flex h-[54px] w-full items-center justify-center rounded-[76px] border border-[#D9DBE9] bg-transparent text-lg text-twilight hover:bg-[#D9DBE9] focus:bg-[#D9DBE9] focus-visible:bg-[#D9DBE9]`,
        className
      )}
    >
      {children}
    </button>
  )
}
