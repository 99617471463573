/**
 * Author: Hridoy Ahmed
 * Website: hridoy.dev
 * Email: learnwithhridoy@gmail.com
 * Updated Date: 16 September 2024
 **/

import Configs from '@/configs'
import { getCookie } from '@/utils/helpers/cookie'
import fetchCsrfToken from '@/api/fetch-csrf-token'
import CONFIGS from '@/configs'

export const getCsrfToken = async method => {
  const csrfRequired = ['POST', 'PUT', 'PATCH', 'DELETE'].includes(method.toUpperCase()) && Configs.isCsrfRequired

  if (csrfRequired && !(await fetchCsrfToken())) {
    console.warn('CSRF Token not fetched, aborting request')

    return false
  }

  return csrfRequired ? getCookie('XSRF-TOKEN') : null
}

export const sendRequest = async (method = 'GET', url = '', postData = {}, queryparams = null, headers = {}) => {
  /* Get Token from local storage */
  // const token = secureLocalStorage.getItem('ptm_token')
  const token = getCookie(CONFIGS.authToken)

  /** If url contains parameter like https://hridoy.com?currentPage=1&showPerPage=10&search=javascript*/
  if (queryparams != null) {
    url += queryparams
  }

  const csrfToken = await getCsrfToken(method)

  try {
    const result = await fetch(url, {
      method: method,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
        ...(!!csrfToken ? { 'X-Xsrf-Token': csrfToken } : {}),
        ...headers
      },
      mode: 'cors',
      redirect: 'follow',
      credentials: 'include',
      referrerPolicy: 'origin',
      body: !['GET', 'DELETE'].includes(method) ? JSON.stringify(postData) : undefined
    })
    let data = await result.json()
    let statusCode = result.status

    return {
      data,
      statusCode
    }
  } catch (error) {
    console.warn(`Root API Error: ${error}`)

    return false
  }
}

export const sendFileRequest = async (method = 'POST', url = '', payload = {}, headers = {}) => {
  /* Get Token from local storage */
  // const encryptedToken = secureLocalStorage.getItem(authConfig.storageTokenKeyName)
  // const token = Helpers.decryptValue(encryptedToken)

  const csrfToken = await getCsrfToken(method)

  try {
    const result = await fetch(url, {
      method: method,
      body: payload,
      headers: {
        Accept: 'application/json',
        contentType: 'application/x-www-form-urlencoded',
        // Authorization: 'Bearer ' + token,
        ...(!!csrfToken ? { 'X-Xsrf-Token': csrfToken } : {}),
        ...headers
      },
      mode: 'cors',
      redirect: 'follow',
      credentials: 'include',
      referrerPolicy: 'origin'
    })

    return await result.json()
  } catch (error) {
    console.warn(`Root API Error: ${error}`)

    return {
      data: null,
      statusCode: 0
    }
  }
}
