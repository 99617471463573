export const nodeENV = process.env.NEXT_PUBLIC_NODE_ENV || 'development'

const mapServer: Record<string, string> = {
  production: `${process.env.NEXT_PUBLIC_API_PROD_URL}/${process.env.NEXT_PUBLIC_API_PROD_VERSION}`,
  stage: `${process.env.NEXT_PUBLIC_API_STAGE_URL}/${process.env.NEXT_PUBLIC_API_STAGE_VERSION}`,
  development: `${process.env.NEXT_PUBLIC_API_DEV_URL}/${process.env.NEXT_PUBLIC_API_DEV_VERSION}`,
  local: `${process.env.NEXT_PUBLIC_API_LOCAL_URL}/${process.env.NEXT_PUBLIC_API_LOCAL_VERSION}`
}

const mapCheckbookServer: Record<string, string> = {
  production: `${process.env.NEXT_PUBLIC_API_DIGITAL_CHECKBOOK_PROD_URL}/${process.env.NEXT_PUBLIC_API_DIGITAL_CHECKBOOK_PROD_VERSION}`,
  stage: `${process.env.NEXT_PUBLIC_API_DIGITAL_CHECKBOOK_DEV_URL}/${process.env.NEXT_PUBLIC_API_DIGITAL_CHECKBOOK_DEV_VERSION}`,
  development: `${process.env.NEXT_PUBLIC_API_DIGITAL_CHECKBOOK_DEV_URL}/${process.env.NEXT_PUBLIC_API_DIGITAL_CHECKBOOK_DEV_VERSION}`,
  local: `${process.env.NEXT_PUBLIC_API_DIGITAL_CHECKBOOK_DEV_URL}/${process.env.NEXT_PUBLIC_API_DIGITAL_CHECKBOOK_DEV_VERSION}`
}

export const server = mapServer[nodeENV]

export const checkbook_server = mapCheckbookServer[nodeENV]

export const baseUrl = process.env.NEXT_PUBLIC_PUBLIC_URL ?? 'http://localhost:3050'

const CONFIGS = Object.freeze({
  env: nodeENV,
  server: server,
  appUrl: baseUrl,
  apiServer: process.env.NEXT_PUBLIC_API_SERVER,
  appName: process.env.NEXT_PUBLIC_APP_NAME ?? 'PayToMe',
  isCsrfRequired: !!Number(process.env.NEXT_PUBLIC_IS_CSRF_REQUIRED),
  authUrl: process.env.NEXT_PUBLIC_ACCOUNTS_URL ?? 'http://localhost:3000',
  apiBaseUrl: process.env.NEXT_PUBLIC_API_BASE_URL ?? 'http://localhost:8000/api',
  defaultRedirectUrl: process.env.NEXT_PUBLIC_DEFAULT_REDIRECT_URL ?? 'http://localhost:3060',
  cookieDomain: process.env.NEXT_PUBLIC_COOKIE_DOMAIN ?? 'localhost',
  publicUrl: process.env.NEXT_PUBLIC_PUBLIC_URL ?? 'http://localhost:3050',
  myAccountUrl: process.env.NEXT_PUBLIC_MY_ACCOUNT_URL ?? 'http://localhost:3060',
  vendorUrl: process.env.NEXT_PUBLIC_VENDOR_URL ?? 'http://localhost:3110',
  merchantUrl: process.env.NEXT_PUBLIC_MERCHANT_URL ?? 'http://localhost:3120',
  authToken: process.env.NEXT_PUBLIC_AUTH_TOKEN ?? '_at'
})

export default CONFIGS
